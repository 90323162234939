define("discourse/plugins/poll/discourse/templates/components/poll-breakdown-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="poll-breakdown-chart-label">{{@group}}</label>
  <canvas class="poll-breakdown-chart-chart"></canvas>
  
  */
  {
    "id": "gc7UTqC7",
    "block": "[[[10,\"label\"],[14,0,\"poll-breakdown-chart-label\"],[12],[1,[30,1]],[13],[1,\"\\n\"],[10,\"canvas\"],[14,0,\"poll-breakdown-chart-chart\"],[12],[13],[1,\"\\n\"]],[\"@group\"],false,[]]",
    "moduleName": "discourse/plugins/poll/discourse/templates/components/poll-breakdown-chart.hbs",
    "isStrictMode": false
  });
});