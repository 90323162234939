define("discourse/plugins/poll/components/poll-breakdown-option", ["exports", "@ember/component", "I18n", "@ember/object", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/poll/lib/chart-colors", "@ember/template", "discourse/lib/computed"], function (_exports, _component, _I18n, _object, _decorators, _computed, _chartColors, _template, _computed2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    // Arguments:
    option: null,
    index: null,
    totalVotes: null,
    optionsCount: null,
    displayMode: null,
    highlightedOption: null,
    onMouseOver: null,
    onMouseOut: null,
    tagName: "",
    highlighted: (0, _computed2.propertyEqual)("highlightedOption", "index"),
    showPercentage: (0, _computed.equal)("displayMode", "percentage"),
    percent(votes, total) {
      return _I18n.default.toNumber(votes / total * 100.0, {
        precision: 1
      });
    },
    optionColors(optionsCount) {
      return (0, _chartColors.getColors)(optionsCount);
    },
    colorBackgroundStyle(highlighted) {
      if (highlighted) {
        // TODO: Use CSS variables (#10341)
        return (0, _template.htmlSafe)("background: rgba(0, 0, 0, 0.1);");
      }
    },
    colorPreviewStyle(highlighted, optionColors, index) {
      const color = highlighted ? window.Chart.helpers.getHoverColor(optionColors[index]) : optionColors[index];
      return (0, _template.htmlSafe)(`background: ${color};`);
    },
    onHover(active) {
      if (active) {
        this.onMouseOver();
      } else {
        this.onMouseOut();
      }
    }
  }, [["method", "percent", [(0, _decorators.default)("option.votes", "totalVotes")]], ["method", "optionColors", [(0, _decorators.default)("optionsCount")]], ["method", "colorBackgroundStyle", [(0, _decorators.default)("highlighted")]], ["method", "colorPreviewStyle", [(0, _decorators.default)("highlighted", "optionColors", "index")]], ["method", "onHover", [_object.action]]]));
});